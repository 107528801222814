const sizes: ISize = {
	miniMobile: 320,
	smallMobile: 414,
	mobile: 576,
	tablet: 768,
	laptop: 1024,
	largeLaptop: 1440,
	desktop: 1920,
	largeDesktop: 2560,
	wideScreen: 3840
};

const devices: IDevices = {
	miniMobile: `(min-width: ${sizes.miniMobile}px)`,
	smallMobile: `(min-width: ${sizes.smallMobile}px)`,
	mobile: `(min-width: ${sizes.mobile}px)`,
	tablet: `(min-width: ${sizes.tablet}px)`,
	laptop: `(min-width: ${sizes.laptop}px)`,
	largeLaptop: `(min-width: ${sizes.largeLaptop}px)`,
	desktop: `(min-width: ${sizes.desktop}px)`
};

const pagePaddings: IPagePaddings = {
	mobile: '16px',
	tablet: '24px'
};

const color: IColor = {
	/** primary */
	primaryColor: '#ff5757',
	/** bg */
	background: '#fff1e7',
	backgroundLight: '#fffae5',
	backgroundDark: '#0a0d1c',
	backgroundWhite: '#ffffff',
	/** text */
	textColor: '#000000',
	textDarkGrey: '#333333',
	textMediumGrey: '#666666',
	textLightGrey: '#999999',
	textVeryLightGrey: '#cccccc',
	textWhite: '#ffffff',
	/** button */
	buttonPrimary: '#10162f',
	buttonWhite: '#ffffff',
	buttonYellow: '#fdecc6',
	buttonBlue: '#d3dcff',
	buttonSky: '#d3f2ff',
	buttonGreen: '#eafec6',
	buttonRed: '#ffd3d3',
	buttonPrimaryHover: '#303342',
	/** border */
	border: '#000000',

	whiteColor: '#ffffff',
	greyColorDark: '#90aae1',
	greyColorDusty: '#979797',
	greyColorLight: '#f5f5f5',
	greyBackgroundColor: '#f6f8f9',
	backgroundColorDark: '#f8faff',
	warmVanilla: '#fffbe5',
	placeHolderColor: '#c0c6d4',
	borderColor: '#c4d0ea',
	borderColorLight: '#efefef',
	shadowColor: 'rgba(0, 0, 0, 0.1)',
	primaryButtonShadow: 'rgba(255, 87, 87, 0.44)',
	scrollBarColor: '#e5e5e5',
	footerLinkColor: '#999999',
	bgColor: '#fff1e7',
	blackColor: '#000000',
	logoBgColor: '#ff6957',
	greyBgColor: '#f8f8f8',
	materialBgColor: '#f9f9f9',
	materialBorderColor: '#e9e9e9',
	wikiSectionBorderColor: '#dcd1c9',
	wikiRegisterButtonColor: '#10162f',
	darkTeal: '#10162f',
	darkBackgroundColor: '#10162f',
	lightYellow: '#effe93',
	lightBlue: '#d5f5f7',
	lightGreen: '#e7febe',
	brightGreen: '#76f70e',
	// study page
	primaryColorHover: '#ffc4c4',
	primaryYellow: '#fdecc6',
	primaryLightYellow: '#fffae5',
	primaryBlue: '#d3f2ff',
	primaryGreen: '#eafec6',
	primaryRed: '#ffd3d3',
	primaryPurple: '#d3dcff',
	primaryDark: '#0a0d1c',
	labelBgColorYellow: '#ffe400',
	labelBgColorGreen: '#d5ff57',
	tagColor: '#fffdfa',
	selectedTagColor: '#f6dfbb',
	adminColor: '#247fff',
	pendingIconColor: '#ffa900',

	// admin page
	adminBgColor: '#f8f8fa',
	successColor: '#52c41a',
	warningColor: '#faad14',
	disabledColor: '#cbcbcb',

	// p3-project page
	backgroundPink: '#ffbbf3',
	textPink: '#98037c',
	backgroundBlue: '#d6e7ff',
	textBlue: '#297dfe',
	backgroundMint: '#d5f5f7',
	textMint: '#009ba4',
	textYellow: '#809702',
	backgroundPurple: '#ddd5fd',
	textPurple: '#6843ff',
	textDeepPurple: '#99027f',
	textAqua: '#009ba4',
	textOrange: '#ff5028',
	// new design
	titleColor: '#10162f',
	// private tutoring
	titleHighlight: '#f1ff9d',
	privateTutoringGreen: '#f1ff9d',
	partnersBoxBackground: '#fafafa'
};

export { color, devices, pagePaddings, sizes };
